<template>
  <div>
    <section class="py-5 bg9 bs-shadow mobile-top">
      <div class="container">
        <h1 class="text-white mb0 lh1">Add a Comment</h1>
        <h5 class="text-white lh1 mb0">Order #: {{orderId}}</h5>
      </div>
    </section>
    <div class="container">
      <div class="d-flex number-showing mt20">
        <router-link
          :to="{ path: $store.state.route.from.fullPath }"
          class="mb0 lh1 mrauto list-btn dib d-flex center"
        >
          <i class="material-icons">keyboard_arrow_left</i>
          Back
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
import Avatar from "vue-avatar";

const auth = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
    //Authorization: "Bearer " + wontworkqwd2iwedjqoewiudwo
  },
};

export default {
  name: "CommentAdd",
  components: {
    AtomSpinner,
    Avatar,
  },
  data() {
    return {
      ordermanageapi: process.env.VUE_APP_ORDER_MANAGE_API,
      orderId: this.$route.params.id,
    };
  },
  methods: {
    firstLoad: function () {
      //this.loading = true;
      this.$store.commit("setActivePage", "order-manage");
      //return axios.get(this.endPointCompute, auth).then((response) => {
      /*
      return axios
        .get(`${this.ordermanageapi}/order/${this.orderId}`, auth)
        .then((response) => {
          console.log(response);
          this.order = response.data;
          this.loading = false;
        });
        */
    },
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },

    colorGetter() {
      return this.$store.state.colors;
    },

    getUsername() {
      return this.$store.state.user;
    },
  },
  created() {
    this.$store.commit("showNavigation");

    // console.log(this.$route.path);
  },
  mounted() {
    this.firstLoad();
  },
};
</script>